let viewgird = [
  {
    path: '/Sys_Log',
    name: 'sys_Log',
    component: () => import('@/views/system/Sys_Log.vue'),
    url: '/views/system/Sys_Log.vue'
  },
  {
    path: '/Sys_User',
    name: 'Sys_User',
    component: () => import('@/views/system/Sys_User.vue'),
    url: '/views/system/Sys_User.vue'
  },
  {
    path: '/permission',
    name: 'permission',
    component: () => import('@/views/system/Permission.vue'),
    url: '/views/system/Permission.vue'
  },

  {
    path: '/Sys_Dictionary',
    name: 'Sys_Dictionary',
    component: () => import('@/views/system/Sys_Dictionary.vue'),
    url: '/views/system/Sys_Dictionary.vue'
  }, {
    path: '/Sys_Role',
    name: 'Sys_Role',
    component: () => import('@/views/system/Sys_Role.vue'),
    url: '/views/system/Sys_Role.vue'
  }
  , {
    path: '/Sys_DictionaryList',
    name: 'Sys_DictionaryList',
    component: () => import('@/views/system/Sys_DictionaryList.vue'),
    url: '/views/system/Sys_DictionaryList.vue'
  }
  , {
    path: '/SellOrder',
    name: 'SellOrder',
    component: () => import('@/views/order/SellOrder.vue'),
    url: '/views/order/SellOrder.vue'
  }, {
    path: '/SellOrder2',
    name: 'SellOrder2',
    component: () => import('@/views/order/SellOrder2.vue'),
    url: '/views/order/SellOrder2.vue'
  }, {
    path: '/SellOrder3',
    name: 'SellOrder3',
    component: () => import('@/views/order/SellOrder3.vue'),
    url: '/views/order/SellOrder3.vue'
  }, {
    path: '/vSellOrderImg',
    name: 'vSellOrderImg',
    component: () => import('@/views/order/vSellOrderImg.vue'),
    url: '/views/order/vSellOrderImg.vue'
  },
  {
    path: '/App_Appointment',
    name: 'App_Appointment',
    component: () => import('@/views/order/App_Appointment.vue'),
    url: '/views/order/App_Appointment.vue'
  },
  {
    path: '/App_Appointment2', //二级表头
    name: 'App_Appointment2',
    component: () => import('@/views/order/App_Appointment2.vue'),
    url: '/views/order/App_Appointment2.vue'
  },
  {
    path: '/App_TransactionAvgPrice',
    name: 'App_TransactionAvgPrice',
    component: () => import('@/views/appmanager/App_TransactionAvgPrice.vue'),
    url: '/views/appmanager/App_TransactionAvgPrice.vue',
    meta: {
      keepAlive: false
    }
  }
  , {
    path: '/App_Expert',
    name: 'App_Expert',
    component: () => import('@/views/appmanager/App_Expert.vue'),
    url: '/views/appmanager/App_Expert.vue'
  }
  , {
    path: '/App_Expert2',
    name: 'App_Expert2',
    component: () => import('@/views/appmanager/App_Expert2.vue'),
    url: '/views/appmanager/App_Expert2.vue'
  }
  , {
    path: '/App_Transaction',
    name: 'App_Transaction',
    component: () => import('@/views/appmanager/App_Transaction.vue'),
    url: '/views/appmanager/App_Transaction.vue'
  }
  , {
    path: '/App_Transaction2',
    name: 'App_Transaction2',
    component: () => import('@/views/appmanager/App_Transaction2.vue'),
    url: '/views/appmanager/App_Transaction2.vue'
  }, {
    path: '/App_ReportPrice',
    name: 'App_ReportPrice',
    component: () => import('@/views/appmanager/App_ReportPrice.vue'),
    url: '/views/appmanager/App_ReportPrice.vue'
  }, {
    path: '/App_News',
    name: 'App_News',
    component: () => import('@/views/appmanager/App_News.vue'),
    url: '/views/appmanager/App_News.vue'
  }, {
    path: '/App_NewsEditor',
    name: 'App_NewsEditor',
    component: () => import('@/views/appmanager/App_NewsEditor.vue'),
    url: '/views/appmanager/App_NewsEditor.vue'
  }, {
    path: '/FormDesignOptions',
    name: 'FormDesignOptions',
    component: () => import('@/views/system/form/FormDesignOptions.vue'),
    url: '/views/system/form/FormDesignOptions.vue'
  }, {
    path: '/FormCollectionObject',
    name: 'FormCollectionObject',
    component: () => import('@/views/system/form/FormCollectionObject.vue'),
    url: '/views/system/form/FormCollectionObject.vue'
  }, {
    path: '/Sys_WorkFlow',
    name: 'Sys_WorkFlow',
    component: () => import('@/views/system/flow/Sys_WorkFlow.vue'),
    url: '/views/system/flow/Sys_WorkFlow.vue'
  }, {
    path: '/Sys_WorkFlowTable',
    name: 'Sys_WorkFlowTable',
    component: () => import('@/views/system/flow/Sys_WorkFlowTable.vue'),
    url: '/views/system/flow/Sys_WorkFlowTable.vue'
  }, {
    path: '/Sys_QuartzOptions',
    name: 'Sys_QuartzOptions',
    component: () => import('@/views/system/quartz/Sys_QuartzOptions.vue'),
    url: '/views/system/quartz/Sys_QuartzOptions.vue'
  }, {
    path: '/Sys_QuartzLog',
    name: 'Sys_QuartzLog',
    component: () => import('@/views/system/quartz/Sys_QuartzLog.vue'),
    url: '/views/system/quartz/Sys_QuartzLog.vue'
  }, {
    path: '/Sys_Department',
    name: 'Sys_Department',
    component: () => import('@/views/system/system/Sys_Department.vue'),
    url: '/views/system/system/Sys_Department.vue'
  }, {
    path: '/Sys_Employee',
    name: 'Sys_Employee',
    component: () => import('@/views/oa/oa/Sys_Employee.vue'),
    url: '/views/oa/oa/Sys_Employee.vue'
  }, {
    path: '/Sys_Position',
    name: 'Sys_Position',
    component: () => import('@/views/system/system/Sys_Position.vue'),
    url: '/views/system/system/Sys_Position.vue'
  }, {
    path: '/Sys_Employee_Apply',
    name: 'Sys_Employee_Apply',
    component: () => import('@/views/oa/oa/Sys_Employee_Apply.vue'),
    url: '/views/oa/oa/Sys_Employee_Apply.vue'
  },
  {
    path: '/EmployeeList',
    name: 'EmployeeList',
    component: () => import('@/views/oa/oa/EmployeeList.vue'),
    url: '/views/oa/oa/EmployeeList.vue'
  }, {
    path: '/Sys_Notice',
    name: 'Sys_Notice',
    component: () => import('@/views/system/system/Sys_Notice.vue'),
    url: '/views/system/system/Sys_Notice.vue'
  }, {
    path: '/EmployeeApplyList',
    name: 'EmployeeApplyList',
    component: () => import('@/views/oa/oa/EmployeeApplyList.vue'),
    url: '/views/oa/oa/EmployeeApplyList.vue'
  }
  , {
    path: '/Sys_EmpBackgroundCheck',
    name: 'Sys_EmpBackgroundCheck',
    component: () => import('@/views/oa/oa/Sys_EmpBackgroundCheck.vue'),
    url: '/views/oa/oa/Sys_EmpBackgroundCheck.vue'
  }, {
    path: '/EmpCheckList',
    name: 'EmpCheckList',
    component: () => import('@/views/oa/oa/EmpCheckList.vue'),
    url: '/views/oa/oa/EmpCheckList.vue'
  },
  // {
  //     path: '/OA_MeetingRoom',
  //     name: 'OA_MeetingRoom',
  //     component: () => import('@/views/system/system/OA_MeetingRoom.vue')
  // }    ,{
  //     path: '/OA_MeetingReserve',
  //     name: 'OA_MeetingReserve',
  //     component: () => import('@/views/system/system/OA_MeetingReserve.vue')
  // },
  {
    path: '/Sys_Message',
    name: 'Sys_Message',
    component: () => import('@/views/system/system/Sys_Message.vue'),
    url: '/views/system/system/Sys_Message.vue'
  }, {
    path: '/MessageSetting',
    name: 'MessageSetting',
    component: () => import('@/views/system/system/MessageSetting.vue'),
    url: '/views/system/system/MessageSetting.vue'
  }, {
    path: '/OA_LeaveApply',
    name: 'OA_LeaveApply',
    component: () => import('@/views/oa/oa/OA_LeaveApply.vue'),
    url: '/views/oa/oa/OA_LeaveApply.vue'
  }, {
    path: '/LeaveApplyList',
    name: 'LeaveApplyList',
    component: () => import('@/views/oa/oa/LeaveApplyList.vue'),
    url: '/views/oa/oa/LeaveApplyList.vue'
  }, {
    path: '/LeaveInterViewList',
    name: 'LeaveInterViewList',
    component: () => import('@/views/oa/oa/LeaveInterViewList.vue'),
    url: '/views/oa/oa/LeaveInterViewList.vue'
  }, {
    path: '/OA_LeaveInterView',
    name: 'OA_LeaveInterView',
    component: () => import('@/views/oa/oa/OA_LeaveInterView.vue'),
    url: '/views/oa/oa/OA_LeaveInterView.vue'
  }, {
    path: '/OA_Dismiss',
    name: 'OA_Dismiss',
    component: () => import('@/views/oa/oa/OA_Dismiss.vue'),
    url: '/views/oa/oa/OA_Dismiss.vue'
  }, {
    path: '/DismissList',
    name: 'DismissList',
    component: () => import('@/views/oa/oa/DismissList.vue'),
    url: '/views/oa/oa/DismissList.vue'
  }, {
    path: '/OA_Confirmation',
    name: 'OA_Confirmation',
    component: () => import('@/views/oa/oa/OA_Confirmation.vue'),
    url: '/views/oa/oa/OA_Confirmation.vue'
  }, {
    path: '/ConfirmationList',
    name: 'ConfirmationList',
    component: () => import('@/views/oa/oa/ConfirmationList.vue'),
    url: '/views/oa/oa/ConfirmationList.vue'
  }, {
    path: '/OA_Handover',
    name: 'OA_Handover',
    component: () => import('@/views/oa/oa/OA_Handover.vue'),
    url: '/views/oa/oa/OA_Handover.vue'
  }, {
    path: '/HandoverList',
    name: 'HandoverList',
    component: () => import('@/views/oa/oa/HandoverList.vue'),
    url: '/views/oa/oa/HandoverList.vue'
  }, {
    path: '/OA_DocumentFile',
    name: 'OA_DocumentFile',
    component: () => import('@/views/oa/personnel/OA_DocumentFile.vue'),
    url: '/views/oa/personnel/OA_DocumentFile.vue'
  }, {
    path: '/OA_DocumentFile2',
    name: 'OA_DocumentFile2',
    component: () => import('@/views/oa/personnel/OA_DocumentFile.vue'),
    url: '/views/oa/personnel/OA_DocumentFile.vue'
  }, {
    path: '/OA_DocumentFileList',
    name: 'OA_DocumentFileList',
    component: () => import('@/views/oa/personnel/OA_DocumentFileList.vue'),
    url: '/views/oa/personnel/OA_DocumentFileList.vue'
  }, {
    path: '/OA_DocumentFileList2',
    name: 'OA_DocumentFileList2',
    component: () => import('@/views/oa/personnel/OA_DocumentFileList2.vue'),
    url: '/views/oa/personnel/OA_DocumentFileList2.vue'
  }, {
    path: '/OA_Award',
    name: 'OA_Award',
    component: () => import('@/views/oa/oa/OA_Award.vue'),
    url: '/views/oa/oa/OA_Award.vue'
  }, {
    path: '/AwardList',
    name: 'AwardList',
    component: () => import('@/views/oa/oa/AwardList.vue'),
    url: '/views/oa/oa/AwardList.vue'
  }, {
    path: '/OA_MeetingReserve',
    name: 'OA_MeetingReserve',
    component: () => import('@/views/oa/oa/OA_MeetingReserve.vue'),
    url: '/views/oa/oa/OA_MeetingReserve.vue'
  }, {
    path: '/MeetingReserveList',
    name: 'MeetingReserveList',
    component: () => import('@/views/oa/oa/MeetingReserveList.vue'),
    url: '/views/oa/oa/MeetingReserveList.vue'
  }, {
    path: '/OA_MeetingRoom',
    name: 'OA_MeetingRoom',
    component: () => import('@/views/oa/oa/OA_MeetingRoom.vue'),
    url: '/views/oa/oa/OA_MeetingRoom.vue'
  }, {
    path: '/MeetingRoomList',
    name: 'MeetingRoomList',
    component: () => import('@/views/oa/oa/MeetingRoomList.vue'),
    url: '/views/oa/oa/MeetingRoomList.vue'
  }, {
    path: '/Sys_WorkFlowStep',
    name: 'Sys_WorkFlowStep',
    component: () => import('@/views/system/flow/Sys_WorkFlowStep.vue'),
    url: '/views/system/flow/Sys_WorkFlowStep.vue'
  }, {
    path: '/Sys_WorkFlowTableStep',
    name: 'Sys_WorkFlowTableStep',
    component: () => import('@/views/system/flow/Sys_WorkFlowTableStep.vue'),
    url: '/views/system/flow/Sys_WorkFlowTableStep.vue'
  }, {
    path: '/Sys_WorkFlowTableAuditLog',
    name: 'Sys_WorkFlowTableAuditLog',
    component: () => import('@/views/system/flow/Sys_WorkFlowTableAuditLog.vue'),
    url: '/views/system/flow/Sys_WorkFlowTableAuditLog.vue'
  }, {
    path: '/Sys_UserDepartment',
    name: 'Sys_UserDepartment',
    component: () => import('@/views/system/system/Sys_UserDepartment.vue'),
    url: '/views/system/system/Sys_UserDepartment.vue'
  }, {
    path: '/Sys_PositionLevel',
    name: 'Sys_PositionLevel',
    component: () => import('@/views/system/system/Sys_PositionLevel.vue'),
    url: '/views/system/system/Sys_PositionLevel.vue'
  }, {
    path: '/PositionList',
    name: 'PositionList',
    component: () => import('@/views/system/system/PositionList.vue'),
    url: '/views/system/system/PositionList.vue'
  }, {
    path: '/OA_EmployeeTurnover',
    name: 'OA_EmployeeTurnover',
    component: () => import('@/views/oa/oa/OA_EmployeeTurnover.vue'),
    url: '/views/oa/oa/OA_EmployeeTurnover.vue'
  }, {
    path: '/EmployeeTurnoverList',
    name: 'EmployeeTurnoverList',
    component: () => import('@/views/oa/oa/EmployeeTurnoverList.vue'),
    url: '/views/oa/oa/EmployeeTurnoverList.vue'
  }, {
    path: '/OA_Asset',
    name: 'OA_Asset',
    component: () => import('@/views/oa/Asset/OA_Asset.vue'),
    url: '/views/oa/Asset/OA_Asset.vue'
  }, {
    path: '/AssetList',
    name: 'AssetList',
    component: () => import('@/views/oa/Asset/AssetList.vue'),
    url: '/views/oa/Asset/AssetList.vue'
  },
  {
    path: '/Sys_TrialPeriodTracking',
    name: 'Sys_TrialPeriodTracking',
    component: () => import('@/views/oa/oa/Sys_TrialPeriodTracking.vue'),
    url: '/views/oa/oa/Sys_TrialPeriodTracking.vue'
  }, {
    path: '/OA_TurnoverHandover',
    name: 'OA_TurnoverHandover',
    component: () => import('@/views/oa/oa/OA_TurnoverHandover.vue'),
    url: '/views/oa/oa/OA_TurnoverHandover.vue'
  }, {
    path: '/TurnoverHandoverList',
    name: 'TurnoverHandoverList',
    component: () => import('@/views/oa/oa/TurnoverHandoverList.vue'),
    url: '/views/oa/oa/TurnoverHandoverList.vue'
  }, {
    path: '/OA_TurnoverHandoverWork',
    name: 'OA_TurnoverHandoverWork',
    component: () => import('@/views/oa/oa/OA_TurnoverHandoverWork.vue'),
    url: '/views/oa/oa/OA_TurnoverHandoverWork.vue'
  }, {
    path: '/todo',
    name: 'todo',
    component: () => import('@/views/system/system/Sys_WorkFlowTable_Todo.vue'),
    url: '/views/system/system/Sys_WorkFlowTable_Todo.vue'
  }, {
    path: '/done',
    name: 'done',
    component: () => import('@/views/system/system/Sys_WorkFlowTable_Done.vue'),
    url: '/views/system/system/Sys_WorkFlowTable_Done.vue'
  }, {
    path: '/Sys_ContractManage',
    name: 'Sys_ContractManage',
    component: () => import('@/views/oa/system/Sys_ContractManage.vue'),
    url: '/views/oa/system/Sys_ContractManage.vue'

  }, {
    path: '/ContractManageList',
    name: 'ContractManageList',
    component: () => import('@/views/oa/oa/ContractManageList.vue'),
    url: '/views/oa/oa/ContractManageList.vue'

  }
  , {
    path: '/Sys_CompanyRules',
    name: 'Sys_CompanyRules',
    component: () => import('@/views/system/system/Sys_CompanyRules.vue'),
    url: '/views/system/system/Sys_CompanyRules.vue'
  },
  {
    path: '/UserDepartmentList',
    name: 'UserDepartmentList',
    component: () => import('@/views/system/system/UserDepartmentList.vue'),
    url: '/views/system/system/UserDepartmentList.vue'

  }, {
    path: '/Sys_InformationBase',
    name: 'Sys_InformationBase',
    component: () => import('@/views/system/system/Sys_InformationBase.vue'),
    url: '/views/system/system/Sys_InformationBase.vue'

  },
  {
    path: '/OA_RenewContrConsult',
    name: 'OA_RenewContrConsult',
    component: () => import('@/views/oa/oa/OA_RenewContrConsult.vue'),
    url: '/views/oa/oa/OA_RenewContrConsult.vue'
  }, {
    path: '/TrialPeriodTrackingList',
    name: 'TrialPeriodTrackingList',
    component: () => import('@/views/oa/oa/TrialPeriodTrackingList.vue'),
    url: '/views/oa/oa/TrialPeriodTrackingList.vue'

  }, {
    path: '/RenewContrConsultList',
    name: 'RenewContrConsultList',
    component: () => import('@/views/oa/oa/RenewContrConsultList.vue'),
    url: '/views/oa/oa/RenewContrConsultList.vue'

  },
  {
    path: '/InformationBaseList',
    name: 'InformationBaseListo',
    component: () => import('@/views/system/system/InformationBaseList.vue'),
    url: '/views/system/system/InformationBaseList.vue'

  },
  {
    path: '/CustodyResponsibilityLetterList',
    name: 'CustodyResponsibilityLetterList',
    component: () => import('@/views/oa/oa/CustodyResponsibilityLetterList.vue'),
    url: '/views/oa/oa/CustodyResponsibilityLetterList.vue'
  },
  {
    path: '/OA_CustodyResponsibilityLetter',
    name: 'OA_CustodyResponsibilityLetter',
    component: () => import('@/views/oa/oa/OA_CustodyResponsibilityLetter.vue'),
    url: '/views/oa/oa/OA_CustodyResponsibilityLetter.vue'
  },
  {
    path: '/Tra_Lecturer',
    name: 'Tra_Lecturer',
    component: () => import('@/views/train/train/Tra_Lecturer.vue'),
    url: '/views/train/train/Tra_Lecturer.vue'
  },
  {
    path: '/LecturerList',
    name: 'LecturerList',
    component: () => import('@/views/train/train/LecturerList.vue'),
    url: '/views/train/train/LecturerList.vue'
  }, {
    path: '/Tra_Course',
    name: 'Tra_Course',
    component: () => import('@/views/train/train/Tra_Course.vue'),
    url: '/views/train/train/Tra_Course.vue'
  }, {
    path: '/CourseList',
    name: 'CourseList',
    component: () => import('@/views/train/train/CourseList.vue'),
    url: '/views/train/train/CourseList.vue'
  }, {
    path: '/Tra_TrainImplementation',
    name: 'Tra_TrainImplementation',
    component: () => import('@/views/train/train/Tra_TrainImplementation.vue'),
    url: '/views/train/train/Tra_TrainImplementation.vue'
  }, {
    path: '/ImplementList',
    name: 'ImplementList',
    component: () => import('@/views/train/train/ImplementList.vue'),
    url: '/views/train/train/ImplementList.vue'
  }, {
    path: '/Tra_AnnualTrainingPlan',
    name: 'Tra_AnnualTrainingPlan',
    component: () => import('@/views/train/train/Tra_AnnualTrainingPlan.vue'),
    url: '/views/train/train/Tra_AnnualTrainingPlan.vue'
  }, {
    path: '/AnnualTrainingPlanList',
    name: 'AnnualTrainingPlanList',
    component: () => import('@/views/train/train/AnnualTrainingPlanList.vue'),
    url: '/views/train/train/AnnualTrainingPlanList.vue'
  }, {
    path: '/Sys_ThirdParty',
    name: 'Sys_ThirdParty',
    component: () => import('@/views/system/system/Sys_ThirdParty.vue'),
    url: '/views/system/system/Sys_ThirdParty.vue'
  }, {
    path: '/OA_ReportApproval',
    name: 'OA_ReportApproval',
    component: () => import('@/views/oa/oa/OA_ReportApproval.vue'),
    url: '/views/oa/oa/OA_ReportApproval.vue'
  }, {
    path: '/ReportApprovalList',
    name: 'ReportApprovalList',
    component: () => import('@/views/oa/oa/ReportApprovalList.vue'),
    url: '/views/oa/oa/ReportApprovalList.vue'
  }, {
    path: '/Sys_EmergencyPlanSetting',
    name: 'Sys_EmergencyPlanSetting',
    component: () => import('@/views/system/emergency/Sys_EmergencyPlanSetting.vue'),
    url: '/views/system/emergency/Sys_EmergencyPlanSetting.vue'
  }, {
    path: '/EmergencyPlanSettingList',
    name: 'EmergencyPlanSettingList',
    component: () => import('@/views/system/emergency/EmergencyPlanSettingList.vue'),
    url: '/views/system/emergency/EmergencyPlanSettingList.vue'
  }, {
    path: '/Sys_EmergencyLaunchPlan',
    name: 'Sys_EmergencyLaunchPlan',
    component: () => import('@/views/system/emergency/Sys_EmergencyLaunchPlan.vue'),
    url: '/views/system/emergency/Sys_EmergencyLaunchPlan.vue'
  }, {
    path: '/EmergencyLaunchPlanList',
    name: 'EmergencyLaunchPlanList',
    component: () => import('@/views/system/emergency/EmergencyLaunchPlanList.vue'),
    url: '/views/system/emergency/EmergencyLaunchPlanList.vue'
  }, {
    path: '/OA_ReportApprovalType',
    name: 'OA_ReportApprovalType',
    component: () => import('@/views/oa/oa/OA_ReportApprovalType.vue'),
    url: '/views/oa/oa/OA_ReportApprovalType.vue'
  }, {
    path: '/FileManagement',
    name: 'FileManagement',
    component: () => import('@/views/oa/FileManagement/DirectoryManagement.vue'),
    url: '/views/oa/FileManagement/DirectoryManagement.vue'
  }, {
    path: '/OA_EmpAccidentRecord',
    name: 'OA_EmpAccidentRecord',
    component: () => import('@/views/oa/oa/OA_EmpAccidentRecord.vue'),
    url: '/views/oa/oa/OA_EmpAccidentRecord.vue'
  }, {
    path: '/EmpAccidentRecordList',
    name: 'EmpAccidentRecordList',
    component: () => import('@/views/oa/oa/EmpAccidentRecordList.vue'),
    url: '/views/oa/oa/EmpAccidentRecordList.vue'
  }, {
    path: '/Qua_Area',
    name: 'Qua_Area',
    component: () => import('@/views/system/quality/Qua_Area.vue'),
    url: '/views/system/quality/Qua_Area.vue'
  }, {
    path: '/QuaAreaList',
    name: 'QuaAreaList',
    component: () => import('@/views/system/quality/QuaAreaList.vue'),
    url: '/views/system/quality/QuaAreaList.vue'
  }, {
    path: '/Qua_Position',
    name: 'Qua_Position',
    component: () => import('@/views/system/quality/Qua_Position.vue'),
    url: '/views/system/quality/Qua_Position.vue'
  }, {
    path: '/QuaPositionList',
    name: 'QuaPositionList',
    component: () => import('@/views/system/quality/QuaPositionList.vue'),
    url: '/views/system/quality/QuaPositionList.vue'
  }, {
    path: '/Qua_DailyContPosition',
    name: 'Qua_DailyContPosition',
    component: () => import('@/views/system/quality/Qua_DailyContPosition.vue'),
    url: '/views/system/quality/Qua_DailyContPosition.vue'
  }, {
    path: '/QuaDailyContPositionList',
    name: 'QuaDailyContPositionList',
    component: () => import('@/views/system/quality/QuaDailyContPositionList.vue'),
    url: '/views/system/quality/QuaDailyContPositionList.vue'
  }
  , {
    path: '/Qua_CheckCont',
    name: 'Qua_CheckCont',
    component: () => import('@/views/system/quality/Qua_CheckCont.vue'),
    url: '/views/system/quality/Qua_CheckCont.vue'
  }, {
    path: '/CheckContAreaList',
    name: 'CheckContAreaList',
    component: () => import('@/views/system/quality/CheckContAreaList.vue'),
    url: '/views/system/quality/CheckContAreaList.vue'
  }, {
    path: '/Qua_SpotCheckRule',
    name: 'Qua_SpotCheckRule',
    component: () => import('@/views/system/quality/SpotCheckRuleList.vue'),
    url: '/views/system/quality/SpotCheckRuleList.vue'
  }, {
    path: '/SpotCheckRuleList',
    name: 'SpotCheckRuleList',
    component: () => import('@/views/system/quality/SpotCheckRuleList.vue'),
    url: '/views/system/quality/SpotCheckRuleList.vue'
  }, {
    path: '/Qua_InspectionSpotCheck',
    name: 'Qua_InspectionSpotCheck',
    component: () => import('@/views/system/quality/Qua_InspectionSpotCheck.vue'),
    url: '/views/system/quality/Qua_InspectionSpotCheck.vue'
  }, {
    path: '/InspectionSpotCheckWeekList',
    name: 'InspectionSpotCheckWeekList',
    component: () => import('@/views/system/quality/InspectionSpotCheckWeekList.vue'),
    url: '/views/system/quality/InspectionSpotCheckWeekList.vue'
  }, {
    path: '/Qua_UnqualifiedCheckData',
    name: 'Qua_UnqualifiedCheckData',
    component: () => import('@/views/system/quality/Qua_UnqualifiedCheckData.vue'),
    url: '/views/system/quality/Qua_UnqualifiedCheckData.vue'
  }, {
    path: '/UnqualifiedCheckDataList',
    name: 'UnqualifiedCheckDataList',
    component: () => import('@/views/system/quality/UnqualifiedCheckDataList.vue'),
    url: '/views/system/quality/UnqualifiedCheckDataList.vue'
  }, {
    path: '/OA_ContractCompliance',
    name: 'OA_ContractCompliance',
    component: () => import('@/views/oa/economiccontract/OA_ContractCompliance.vue'),
    url: '/views/oa/economiccontract/OA_ContractCompliance.vue'
  }, {
    path: '/ContractComplianceList',
    name: 'ContractComplianceList',
    component: () => import('@/views/oa/economiccontract/ContractComplianceList.vue'),
    url: '/views/oa/economiccontract/ContractComplianceList.vue'
  }, {
    path: '/OA_ContractType',
    name: 'OA_ContractType',
    component: () => import('@/views/oa/economiccontract/OA_ContractType.vue'),
    url: '/views/oa/economiccontract/OA_ContractType.vue'
  }, {
    path: '/OA_ContractCompliance_Collection',
    name: 'OA_ContractCompliance_Collection',
    component: () => import('@/views/oa/economiccontract/OA_ContractCompliance_Collection.vue'),
    url: '/views/oa/economiccontract/OA_ContractCompliance_Collection.vue'
  }, {
    path: '/CollectionList',
    name: 'CollectionList',
    component: () => import('@/views/oa/economiccontract/CollectionList.vue'),
    url: '/views/oa/economiccontract/CollectionList.vue'
  }, {
    path: '/OA_ContractCompliance_Payment',
    name: 'OA_ContractCompliance_Payment',
    component: () => import('@/views/oa/economiccontract/OA_ContractCompliance_Payment.vue'),
    url: '/views/oa/economiccontract/OA_ContractCompliance_Payment.vue'
  }, {

    path: '/PaymentList',
    name: 'PaymentList',
    component: () => import('@/views/oa/economiccontract/PaymentList.vue'),
    url: '/views/oa/economiccontract/PaymentList.vue'
  }, {
    path: '/OA_InductionTraining',
    name: 'OA_InductionTraining',
    component: () => import('@/views/oa/oa/OA_InductionTraining.vue'),
    url: '/views/oa/oa/OA_InductionTraining.vue'
  }, {
    path: '/InductionTrainingList',
    name: 'InductionTrainingList',
    component: () => import('@/views/oa/oa/InductionTrainingList.vue'),
    url: '/views/oa/oa/InductionTrainingList.vue'
  }, {
    path: '/DevDocumentation',
    name: 'DevDocumentation',
    component: () => import('@/views/DevDocumentation.vue'),
    url: '/views/DevDocumentation.vue'
  }, {
    path: '/OA_DocumentBorrow',
    name: 'OA_DocumentBorrow',
    component: () => import('@/views/oa/oa/OA_DocumentBorrow.vue'),
    url: '/views/oa/oa/OA_DocumentBorrow.vue'
  }, {
    path: '/OA_AssetNullify',
    name: 'OA_AssetNullify',
    component: () => import('@/views/oa/Asset/OA_AssetNullify.vue'),
    url: '/views/oa/Asset/OA_AssetNullify.vue'
  }, {
    path: '/SendMessage',
    name: 'SendMessage',
    component: () => import('@/views/system/SendMessage.vue'),
    url: '/views/system/SendMessage.vue'
  }, {
    path: '/CheckContPositionList',
    name: 'CheckContPositionList',
    component: () => import('@/views/system/quality/CheckContPositionList.vue'),
    url: '/views/system/quality/CheckContPositionList.vue'
  }, {
    path: '/Qua_InspectionSpotCheckMonth',
    name: 'Qua_InspectionSpotCheckMonth',
    component: () => import('@/views/system/quality/Qua_InspectionSpotCheckMonth.vue'),
    url: '/views/system/quality/Qua_InspectionSpotCheckMonth.vue'
  }, {
    path: '/InspectionSpotCheckMonthList',
    name: 'InspectionSpotCheckMonthList',
    component: () => import('@/views/system/quality/InspectionSpotCheckMonthList.vue'),
    url: '/views/system/quality/InspectionSpotCheckMonthList.vue'
  }, {
    path: '/InspectionSpotCheckYearList',
    name: 'InspectionSpotCheckYearList',
    component: () => import('@/views/system/quality/InspectionSpotCheckYearList.vue'),
    url: '/views/system/quality/InspectionSpotCheckYearList.vue'
  }, {
    path: '/InspectionSpotCheckDayList',
    name: 'InspectionSpotCheckDayList',
    component: () => import('@/views/system/quality/InspectionSpotCheckDayList.vue'),
    url: '/views/system/quality/InspectionSpotCheckDayList.vue'
  }, {
    path: '/OA_AwardSupplier',
    name: 'OA_AwardSupplier',
    component: () => import('@/views/oa/safety/OA_AwardSupplier.vue'),
    url: '/views/oa/safety/OA_AwardSupplier.vue'
  }, {
    path: '/AwardSupplierList',
    name: 'AwardSupplierList',
    component: () => import('@/views/oa/safety/AwardSupplierList.vue'),
    url: '/views/oa/safety/AwardSupplierList.vue'
  }, {
    path: '/Fire_OrgInfo',
    name: 'Fire_OrgInfo',
    component: () => import('@/views/fire/Fire_OrgInfo.vue'),
    url: '/views/fire/Fire_OrgInfo.vue'
  }, {
    path: '/FireOrgList',
    name: 'FireOrgList',
    component: () => import('@/views/fire/FireOrgList.vue'),
    url: '/views/fire/FireOrgList.vue'
  }, {
    path: '/Fire_Device',
    name: 'Fire_Device',
    component: () => import('@/views/fire/Fire_Device.vue'),
    url: '/views/fire/Fire_Device.vue'
  }, {
    path: '/FireDeviceList',
    name: 'FireDeviceList',
    component: () => import('@/views/fire/FireDeviceList.vue'),
    url: '/views/fire/FireDeviceList.vue'
  }, {
    path: '/Sys_TrialPeriodTracking_MainBase',
    name: 'Sys_TrialPeriodTracking_MainBase',
    component: () => import('@/views/oa/oa/Sys_TrialPeriodTracking_MainBase.vue'),
    url: '/views/oa/oa/Sys_TrialPeriodTracking_MainBase.vue'
  }, {
    path: '/OA_AssetChanges',
    name: 'OA_AssetChanges',
    component: () => import('@/views/oa/Asset/OA_AssetChanges.vue'),
    url: '/views/oa/Asset/OA_AssetChanges.vue'
  }, {
    path: '/Pur_MaterialsInfo',
    name: 'Pur_MaterialsInfo',
    component: () => import('@/views/system/purchase/Pur_MaterialsInfo.vue'),
    url: '/views/system/purchase/Pur_MaterialsInfo.vue'
  }, {
    path: '/Pur_MaterialsType',
    name: 'Pur_MaterialsType',
    component: () => import('@/views/system/purchase/Pur_MaterialsType.vue'),
    url: '/views/system/purchase/Pur_MaterialsType.vue'
  }, {
    path: '/Pur_Warehouse',
    name: 'Pur_Warehouse',
    component: () => import('@/views/system/purchase/Pur_Warehouse.vue'),
    url: '/views/system/purchase/Pur_Warehouse.vue'
  }, {
    path: '/MaterialsInfoList',
    name: 'MaterialsInfoList',
    component: () => import('@/views/system/purchase/MaterialsInfoList.vue'),
    url: '/views/system/purchase/MaterialsInfoList.vue'
  }, {
    path: '/MaterialsTypeList',
    name: 'MaterialsTypeList',
    component: () => import('@/views/system/purchase/MaterialsTypeList.vue'),
    url: '/views/system/purchase/MaterialsTypeList.vue'
  }, {
    path: '/OA_ContractCompliance_Condition',
    name: 'OA_ContractCompliance_Condition',
    component: () => import('@/views/oa/economiccontract/OA_ContractCompliance_Condition.vue'),
    url: '/views/oa/economiccontract/OA_ContractCompliance_Condition.vue'
  }, {
    path: '/ConditionList',
    name: 'ConditionList',
    component: () => import('@/views/oa/economiccontract/ConditionList.vue'),
    url: '/views/oa/economiccontract/ConditionList.vue'

  }, {
    path: '/Sys_TrialPeriodTracking_MainBase',
    name: 'Sys_TrialPeriodTracking_MainBase',
    component: () => import('@/views/oa/oa/Sys_TrialPeriodTracking_MainBase.vue'),
    url: '/views/oa/oa/Sys_TrialPeriodTracking_MainBase.vue'
  }, {
    path: '/OA_AssetChanges',
    name: 'OA_AssetChanges',
    component: () => import('@/views/oa/Asset/OA_AssetChanges.vue'),
    url: '/views/oa/Asset/OA_AssetChanges.vue'
  }, {
    path: '/MeetingStatisticsList',
    name: 'MeetingStatisticsList',
    component: () => import('@/views/oa/oa/MeetingStatisticsList.vue'),
    url: '/views/oa/oa/MeetingStatisticsList.vue'
  }, {
    path: '/Pur_Supplier',
    name: 'Pur_Supplier',
    component: () => import('@/views/system/purchase/Pur_Supplier.vue'),
    url: '/views/system/purchase/Pur_Supplier.vue'
  }, {
    path: '/OA_ReportRepair',
    name: 'OA_ReportRepair',
    component: () => import('@/views/oa/oa/OA_ReportRepair.vue'),
    url: '/views/oa/oa/OA_ReportRepair.vue'
  }, {
    path: '/Pur_PurchaseApply',
    name: 'Pur_PurchaseApply',
    component: () => import('@/views/system/purchase/Pur_PurchaseApply.vue'),
    url: '/views/system/purchase/Pur_PurchaseApply.vue'
  }, {
    path: '/PurchaseApplyList',
    name: 'PurchaseApplyList',
    component: () => import('@/views/system/purchase/PurchaseApplyList.vue'),
    url: '/views/system/purchase/PurchaseApplyList.vue'
  }, {
    path: '/Pur_StorageInspection',
    name: 'Pur_StorageInspection',
    component: () => import('@/views/system/purchase/Pur_StorageInspection.vue'),
    url: '/views/system/purchase/Pur_StorageInspection.vue'
  }, {
    path: '/Pur_ReturnExchange',
    name: 'Pur_ReturnExchange',
    component: () => import('@/views/system/purchase/Pur_ReturnExchange.vue'),
    url: '/views/system/purchase/Pur_ReturnExchange.vue'
  }, {
    path: '/CheckContProjectList',
    name: 'CheckContProjectList',
    component: () => import('@/views/system/quality/CheckContProjectList.vue'),
    url: '/views/system/quality/CheckContProjectList.vue'
  }, {

    path: '/MeetingWaitTaskList',
    name: 'MeetingWaitTaskList',
    component: () => import('@/views/oa/oa/MeetingWaitTaskList.vue'),
    url: '/views/oa/oa/MeetingWaitTaskList.vue'

  }, {
    path: '/Pur_MaterialsStock',
    name: 'Pur_MaterialsStock',
    component: () => import('@/views/system/purchase/Pur_MaterialsStock.vue'),
    url: '/views/system/purchase/Pur_MaterialsStock.vue'
  }, {
    path: '/MaterialsStockList',
    name: 'MaterialsStockList',
    component: () => import('@/views/system/purchase/MaterialsStockList.vue'),
    url: '/views/system/purchase/MaterialsStockList.vue'
  }, {
    path: '/Pur_StockOut',
    name: 'Pur_StockOut',
    component: () => import('@/views/system/purchase/Pur_StockOut.vue'),
    url: '/views/system/purchase/Pur_StockOut.vue'
  }, {
    path: '/StockOutList',
    name: 'StockOutList',
    component: () => import('@/views/system/purchase/StockOutList.vue'),
    url: '/views/system/purchase/StockOutList.vue'
  }, {
    path: '/Qua_CheckProject',
    name: 'Qua_CheckProject',
    component: () => import('@/views/system/quality/Qua_CheckProject.vue'),
    url: '/views/system/quality/Qua_CheckProject.vue'
  }, {
    path: '/CheckProjectList',
    name: 'CheckProjectList',
    component: () => import('@/views/system/quality/CheckProjectList.vue'),
    url: '/views/system/quality/CheckProjectList.vue'
  }, {
    path: '/QuaInspectionSpotCheckDayList',
    name: 'QuaInspectionSpotCheckDayList',
    component: () => import('@/views/system/quality/QuaInspectionSpotCheckDayList.vue'),
    url: '/views/system/quality/QuaInspectionSpotCheckDayList.vue'
  }, {
    path: '/QuaInspectionSpotCheckWeekList',
    name: 'QuaInspectionSpotCheckWeekList',
    component: () => import('@/views/system/quality/QuaInspectionSpotCheckWeekList.vue'),
    url: '/views/system/quality/QuaInspectionSpotCheckWeekList.vue'
  }, {
    path: '/QuaInspectionSpotCheckMonthList',
    name: 'QuaInspectionSpotCheckMonthList',
    component: () => import('@/views/system/quality/QuaInspectionSpotCheckMonthList.vue'),
    url: '/views/system/quality/QuaInspectionSpotCheckMonthList.vue'
  }, {
    path: '/QuaInspectionSpotCheckYearList',
    name: 'QuaInspectionSpotCheckYearList',
    component: () => import('@/views/system/quality/QuaInspectionSpotCheckYearList.vue'),
    url: '/views/system/quality/QuaInspectionSpotCheckYearList.vue'
  }, {
    path: '/Pur_PaymentApply',
    name: 'Pur_PaymentApply',
    component: () => import('@/views/system/purchase/Pur_PaymentApply.vue'),
    url: '/views/system/purchase/Pur_PaymentApply.vue'
  }, {
    path: '/Finan_ExpensesClaim',
    name: 'Finan_ExpensesClaim',
    component: () => import('@/views/system/finance/Finan_ExpensesClaim.vue'),
    url: '/views/system/finance/Finan_ExpensesClaim.vue'
  }, {
    path: '/ExpensesClaimList',
    name: 'ExpensesClaimList',
    component: () => import('@/views/system/finance/ExpensesClaimList.vue'),
    url: '/views/system/finance/ExpensesClaimList.vue'
  }, {
    path: '/Finan_ExpensesClaimType',
    name: 'Finan_ExpensesClaimType',
    component: () => import('@/views/system/finance/Finan_ExpensesClaimType.vue'),
    url: '/views/system/finance/Finan_ExpensesClaimType.vue'
  }, {
    path: '/Licen_LicenceInfo',
    name: 'Licen_LicenceInfo',
    component: () => import('@/views/system/licence/Licen_LicenceInfo.vue'),
    url: '/views/system/licence/Licen_LicenceInfo.vue'
  }, {
    path: '/Licen_LicenceBorrow',
    name: 'Licen_LicenceBorrow',
    component: () => import('@/views/system/licence/Licen_LicenceBorrow.vue'),
    url: '/views/system/licence/Licen_LicenceBorrow.vue'
  }, {
    path: '/Licen_LicenceReturn',
    name: 'Licen_LicenceReturn',
    component: () => import('@/views/system/licence/Licen_LicenceReturn.vue'),
    url: '/views/system/licence/Licen_LicenceReturn.vue'
  }, {
    path: '/Licen_LicenceLogOff',
    name: 'Licen_LicenceLogOff',
    component: () => import('@/views/system/licence/Licen_LicenceLogOff.vue'),
    url: '/views/system/licence/Licen_LicenceLogOff.vue'

  }, {
    path: '/OA_Seal',
    name: 'OA_Seal',
    component: () => import('@/views/oa/oa/OA_Seal.vue'),
    url: '/views/oa/oa/OA_Seal.vue'
  }, {
    path: '/OA_SealApply',
    name: 'OA_SealApply',
    component: () => import('@/views/oa/oa/OA_SealApply.vue'),
    url: '/views/oa/oa/OA_SealApply.vue'
  }, {
    path: '/OA_SealEngraveApply',
    name: 'OA_SealEngraveApply',
    component: () => import('@/views/oa/oa/OA_SealEngraveApply.vue'),
    url: '/views/oa/oa/OA_SealEngraveApply.vue'
  }, {
    path: '/OA_SealAbolish',
    name: 'OA_SealAbolish',
    component: () => import('@/views/oa/oa/OA_SealAbolish.vue'),
    url: '/views/oa/oa/OA_SealAbolish.vue'
  }, {
    path: '/OA_AssetUse',
    name: 'OA_AssetUse',
    component: () => import('@/views/oa/oa/OA_AssetUse.vue'),
    url: '/views/oa/oa/OA_AssetUse.vue'
  }, {
    path: '/Pur_PurchaseOrder',
    name: 'Pur_PurchaseOrder',
    component: () => import('@/views/system/purchase/Pur_PurchaseOrder.vue'),
    url: '/views/system/purchase/Pur_PurchaseOrder.vue'
  }, {
    path: '/Pur_IndemnityNote',
    name: 'Pur_IndemnityNote',
    component: () => import('@/views/system/purchase/Pur_IndemnityNote.vue'),
    url: '/views/system/purchase/Pur_IndemnityNote.vue'

  }, {
    path: '/Qua_CheckPosition',
    name: 'Qua_CheckPosition',
    component: () => import('@/views/system/quality/Qua_CheckPosition.vue'),
    url: '/views/system/quality/Qua_CheckPosition.vue'
  }, {
    path: '/Sys_Facility_Library',
    name: 'Sys_Facility_Library',
    component: () => import('@/views/system/Sys_Facility_Library.vue'),
    url: '/views/system/Sys_Facility_Library.vue'
  }, {
    path: '/Sys_FireFacility_Library',
    name: 'Sys_FireFacility_Library',
    component: () => import('@/views/system/Sys_FireFacility_Library.vue'),
    url: '/views/system/Sys_FireFacility_Library.vue'
  }, {
    path: '/OA_AssetMaintenance',
    name: 'OA_AssetMaintenance',
    component: () => import('@/views/oa/oa/OA_AssetMaintenance.vue'),
    url: '/views/oa/oa/OA_AssetMaintenance.vue'
  }, {
    path: '/KPI_ExamineInfo',
    name: 'KPI_ExamineInfo',
    component: () => import('@/views/system/kpi/KPI_ExamineInfo.vue'),
    url: '/views/system/kpi/KPI_ExamineInfo.vue'
  }, {
    path: '/KPI_ExamineEvalLevel',
    name: 'KPI_ExamineEvalLevel',
    component: () => import('@/views/system/kpi/KPI_ExamineEvalLevel.vue'),
    url: '/views/system/kpi/KPI_ExamineEvalLevel.vue'
  }, {
    path: '/KPI_Examine',
    name: 'KPI_Examine',
    component: () => import('@/views/system/kpi/KPI_Examine.vue'),
    url: '/views/system/kpi/KPI_Examine.vue'
  }, {
    path: '/WP_WorkPlan',
    name: 'WP_WorkPlan',
    component: () => import('@/views/oa/workplan/WP_WorkPlan.vue'),
    url: '/views/oa/workplan/WP_WorkPlan.vue'
  }, {
    path: '/WP_DailyTask',
    name: 'WP_DailyTask',
    component: () => import('@/views/oa/workplan/WP_DailyTask.vue'),
    url: '/views/oa/workplan/WP_DailyTask.vue'
  }, {
    path: '/WorkPlanList',
    name: 'WorkPlanList',
    component: () => import('@/views/oa/workplan/WorkPlanList.vue'),
    url: '/views/oa/workplan/WorkPlanList.vue'
  }, {
    path: '/DailyTaskList',
    name: 'DailyTaskList',
    component: () => import('@/views/oa/workplan/DailyTaskList.vue'),
    url: '/views/oa/workplan/DailyTaskList.vue'
  }, {
    path: '/vTable_Info',
    name: 'vTable_Info',
    component: () => import('@/views/system/vtable_info/vTable_Info.vue'),
    url: '/views/system/vtable_info/vTable_Info.vue'
  }, {
    path: '/OA_Car',
    name: 'OA_Car',
    component: () => import('@/views/system/form/OA_Car.vue'),
    url: '/views/system/form/OA_Car.vue'
  }, {
    path: '/Form_CarMorningCheck',
    name: 'Form_CarMorningCheck',
    component: () => import('@/views/system/form/Form_CarMorningCheck.vue'),
    url: '/views/system/form/Form_CarMorningCheck.vue'
  }, {
    path: '/Form_CarDrivingRecords',
    name: 'Form_CarDrivingRecords',
    component: () => import('@/views/system/form/Form_CarDrivingRecords.vue'),
    url: '/views/system/form/Form_CarDrivingRecords.vue'
  }, {
    path: '/Form_CarPsLzGjQsRecords',
    name: 'Form_CarPsLzGjQsRecords',
    component: () => import('@/views/system/form/Form_CarPsLzGjQsRecords.vue'),
    url: '/views/system/form/Form_CarPsLzGjQsRecords.vue'
  }, {
    path: '/Form_CarMaintenanceRecords',
    name: 'Form_CarMaintenanceRecords',
    component: () => import('@/views/system/form/Form_CarMaintenanceRecords.vue'),
    url: '/views/system/form/Form_CarMaintenanceRecords.vue'
  }, {
    path: '/Form_CarDisinfectRecords',
    name: 'Form_CarDisinfectRecords',
    component: () => import('@/views/system/form/Form_CarDisinfectRecords.vue'),
    url: '/views/system/form/Form_CarDisinfectRecords.vue'
  }, {
    path: '/Form_ChemicalUseRecord',
    name: 'Form_ChemicalUseRecord',
    component: () => import('@/views/system/form/Form_ChemicalUseRecord.vue'),
    url: '/views/system/form/Form_ChemicalUseRecord.vue'
  }, {
    path: '/Form_MachineryEquipmentRecord',
    name: 'Form_MachineryEquipmentRecord',
    component: () => import('@/views/system/form/Form_MachineryEquipmentRecord.vue'),
    url: '/views/system/form/Form_MachineryEquipmentRecord.vue'
  }, {
    path: '/Form_TempDisinfectRecord',
    name: 'Form_TempDisinfectRecord',
    component: () => import('@/views/system/form/Form_TempDisinfectRecord.vue'),
    url: '/views/system/form/Form_TempDisinfectRecord.vue'
  }, {
    path: '/Form_DisinfectRecord',
    name: 'Form_DisinfectRecord',
    component: () => import('@/views/system/form/Form_DisinfectRecord.vue'),
    url: '/views/system/form/Form_DisinfectRecord.vue'

  }, {
    path: '/Form_UltravioletLightSpotCheck',
    name: 'Form_UltravioletLightSpotCheck',
    component: () => import('@/views/system/form/Form_UltravioletLightSpotCheck.vue'),
    url: '/views/system/form/Form_UltravioletLightSpotCheck.vue'

  }, {
    path: '/Form_CarToolsUseRecords',
    name: 'Form_CarToolsUseRecords',
    component: () => import('@/views/system/form/Form_CarToolsUseRecords.vue'),
    url: '/views/system/form/Form_CarToolsUseRecords.vue'
  }, {
    path: '/Form_CarToolsListCheck',
    name: 'Form_CarToolsListCheck',
    component: () => import('@/views/system/form/Form_CarToolsListCheck.vue'),
    url: '/views/system/form/Form_CarToolsListCheck.vue'

  }, {
    path: '/Form_SteamDisinfectRecord',
    name: 'Form_SteamDisinfectRecord',
    component: () => import('@/views/system/form/Form_SteamDisinfectRecord.vue'),
    url: '/views/system/form/Form_SteamDisinfectRecord.vue'
  }, {
    path: '/Form_PlaceClearRecord',
    name: 'Form_PlaceClearRecord',
    component: () => import('@/views/system/form/Form_PlaceClearRecord.vue'),
    url: '/views/system/form/Form_PlaceClearRecord.vue'
  }, {
    path: '/Form_ProductRecallRecord',
    name: 'Form_ProductRecallRecord',
    component: () => import('@/views/system/form/Form_ProductRecallRecord.vue'),
    url: '/views/system/form/Form_ProductRecallRecord.vue'
  }, {
    path: '/Form_JtXdcYsGhRecords',
    name: 'Form_JtXdcYsGhRecords',
    component: () => import('@/views/system/form/Form_JtXdcYsGhRecords.vue'),
    url: '/views/system/form/Form_JtXdcYsGhRecords.vue'
  }, {
    path: '/Form_DeviceClearRecord',
    name: 'Form_DeviceClearRecord',
    component: () => import('@/views/system/form/Form_DeviceClearRecord.vue'),
    url: '/views/system/form/Form_DeviceClearRecord.vue'
  }, {
    path: '/Form_DisinfectWareHourseTempRecord',
    name: 'Form_DisinfectWareHourseTempRecord',
    component: () => import('@/views/system/form/Form_DisinfectWareHourseTempRecord.vue'),
    url: '/views/system/form/Form_DisinfectWareHourseTempRecord.vue'
  }, {
    path: '/OA_ConfirmExamineEvalLevel',
    name: 'OA_ConfirmExamineEvalLevel',
    component: () => import('@/views/oa/oa/OA_ConfirmExamineEvalLevel.vue'),
    url: "/views/oa/oa/OA_ConfirmExamineEvalLevel.vue"
  }, {
    path: '/OA_ConfirmExamineInfo',
    name: 'OA_ConfirmExamineInfo',
    component: () => import('@/views/oa/oa/OA_ConfirmExamineInfo.vue'),
    url: "/views/oa/oa/OA_ConfirmExamineInfo.vue"
  }, {
    path: '/Form_FloodlightSpotCheck',
    name: 'Form_FloodlightSpotCheck',
    component: () => import('@/views/system/form/Form_UltravioletLightSpotCheck.vue'),
    url: "/views/system/form/Form_UltravioletLightSpotCheck.vue"
  }, {
    path: '/Form_TablewareDisinfection',
    name: 'Form_TablewareDisinfection',
    component: () => import('@/views/system/form/Form_TablewareDisinfection.vue'),
    url: "/views/system/form/Form_TablewareDisinfection.vue"
  }, {
    path: '/Form_DistributionData',
    name: 'Form_DistributionData',
    component: () => import('@/views/system/form/Form_DistributionData.vue'),
    url: "/views/system/form/Form_DistributionData.vue"
  }, {
    path: '/Form_FinishedProductLedger',
    name: 'Form_FinishedProductLedger',
    component: () => import('@/views/system/form/Form_FinishedProductLedger.vue'),
    url: "/views/system/form/Form_FinishedProductLedger.vue"
  }, {
    path: '/Sys_DeviceCheck',
    name: 'Sys_DeviceCheck',
    component: () => import('@/views/system/system/Sys_DeviceCheck.vue'),
    url: "/views/system/system/Sys_DeviceCheck.vue"
  }, {
    path: '/Sys_DeviceCheckError',
    name: 'Sys_DeviceCheckError',
    component: () => import('@/views/system/system/Sys_DeviceCheckError.vue'),
    url: "/views/system/system/Sys_DeviceCheckError.vue"
  }, {
    path: '/Sys_DeviceInventory',
    name: 'Sys_DeviceInventory',
    component: () => import('@/views/system/system/Sys_DeviceInventory.vue'),
    url: "/views/system/system/Sys_DeviceInventory.vue"
  }, {
    path: '/OA_RepunchAttendanceCard',
    name: 'OA_RepunchAttendanceCard',
    component: () => import('@/views/oa/oa/OA_RepunchAttendanceCard.vue'),
    url: "/views/oa/oa/OA_RepunchAttendanceCard.vue"
  }, {
    path: '/Sys_DeviceMaintenancePolicy',
    name: 'Sys_DeviceMaintenancePolicy',
    component: () => import('@/views/system/system/Sys_DeviceMaintenancePolicy.vue'),
    url: "/views/system/system/Sys_DeviceMaintenancePolicy.vue"
  }, {
    path: '/Sys_DeviceInspectionProject',
    name: 'Sys_DeviceInspectionProject',
    component: () => import('@/views/system/system/Sys_DeviceInspectionProject.vue'),
    url: "/views/system/system/Sys_DeviceInspectionProject.vue"
  }, {
    path: '/Sys_DeviceInspection',
    name: 'Sys_DeviceInspection',
    component: () => import('@/views/system/system/Sys_DeviceInspection.vue'),
    url: "/views/system/system/Sys_DeviceInspection.vue"
  }, {
    path: '/Sys_DeviceReportingForRepair',
    name: 'Sys_DeviceReportingForRepair',
    component: () => import('@/views/system/system/Sys_DeviceReportingForRepair.vue'),
    url: "/views/system/system/Sys_DeviceReportingForRepair.vue"
  }, {
    path: '/Sys_DeviceMaintenanceType',
    name: 'Sys_DeviceMaintenanceType',
    component: () => import('@/views/system/system/Sys_DeviceMaintenanceType.vue'),
    url: "/views/system/system/Sys_DeviceMaintenanceType.vue"
  }, {
    path: '/Sys_DeviceMaintenancePlan',
    name: 'Sys_DeviceMaintenancePlan',
    component: () => import('@/views/system/system/Sys_DeviceMaintenancePlan.vue'),
    url: "/views/system/system/Sys_DeviceMaintenancePlan.vue"
  }, {
    path: '/Fire_DeviceDetector',
    name: 'Fire_DeviceDetector',
    component: () => import('@/views/fire/Fire_DeviceDetector.vue'),
    url: "/views/fire/Fire_DeviceDetector.vue"
  }, {
    path: '/Fire_EventLog',
    name: 'Fire_EventLog',
    component: () => import('@/views/fire/Fire_EventLog.vue'),
    url: "/views/fire/Fire_EventLog.vue"
  }, {
    path: '/Fire_EventLog1',
    name: 'Fire_EventLog1',
    component: () => import('@/views/fire/Fire_EventLog1.vue'),
    url: "/views/fire/Fire_EventLog1.vue"
  }, {
    path: '/Fire_EventLog2',
    name: 'Fire_EventLog2',
    component: () => import('@/views/fire/Fire_EventLog2.vue'),
    url: "/views/fire/Fire_EventLog2.vue"
  }, {
    path: '/Fire_OnlineOfflineLog',
    name: 'Fire_OnlineOfflineLog',
    component: () => import('@/views/fire/Fire_OnlineOfflineLog.vue'),
    url: "/views/fire/Fire_OnlineOfflineLog.vue"
  }, {
    path: '/Fire_PartnerAccount',
    name: 'Fire_PartnerAccount',
    component: () => import('@/views/fire/Fire_PartnerAccount.vue'),
    url: "/views/fire/Fire_PartnerAccount.vue"
  }, {
    path: '/Fire_HousingEstate',
    name: 'Fire_HousingEstate',
    component: () => import('@/views/fire/Fire_HousingEstate.vue'),
    url: "/views/fire/Fire_HousingEstate.vue"
  }, {
    path: '/Fire_Camera',
    name: 'Fire_Camera',
    component: () => import('@/views/fire/Fire_Camera.vue'),
    url: "/views/fire/Fire_Camera.vue"
  }, {
    path: '/Sys_RoleDepartment',
    name: 'Sys_RoleDepartment',
    component: () => import('@/views/oa/oa/Sys_RoleDepartment.vue'),
    url: "/views/oa/oa/Sys_RoleDepartment.vue"
  }
  , {
    path: '/Fire_InnoproWarnDevice',
    name: 'Fire_InnoproWarnDevice',
    component: () => import('@/views/fire/Fire_InnoproWarnDevice.vue'),
    url: "/views/fire/Fire_InnoproWarnDevice.vue"
  }, {
    path: '/Fire_InnoproWarnDeviceEventLog',
    name: 'Fire_InnoproWarnDeviceEventLog',
    component: () => import('@/views/fire/Fire_InnoproWarnDeviceEventLog.vue'),
    url: "/views/fire/Fire_InnoproWarnDeviceEventLog.vue"
  }, {
    path: '/Fire_InnoproWarnDeviceEventLogError',
    name: 'Fire_InnoproWarnDeviceEventLogError',
    component: () => import('@/views/fire/Fire_InnoproWarnDeviceEventLogError.vue'),
    url: "/views/fire/Fire_InnoproWarnDeviceEventLogError.vue"
  }, {
    path: '/Fire_InnoproWarnDeviceEventLogOther',
    name: 'Fire_InnoproWarnDeviceEventLogOther',
    component: () => import('@/views/fire/Fire_InnoproWarnDeviceEventLogOther.vue'),
    url: "/views/fire/Fire_InnoproWarnDeviceEventLogOther.vue"
  }, {
    path: '/Fire_InnoproWarnDeviceEventLogStatus',
    name: 'Fire_InnoproWarnDeviceEventLogStatus',
    component: () => import('@/views/fire/Fire_InnoproWarnDeviceEventLogStatus.vue'),
    url: "/views/fire/Fire_InnoproWarnDeviceEventLogStatus.vue"
  }, {
    path: '/Fire_InnoproWarnHost',
    name: 'Fire_InnoproWarnHost',
    component: () => import('@/views/fire/Fire_InnoproWarnHost.vue'),
    url: "/views/fire/Fire_InnoproWarnHost.vue"
  }, {
    path: '/Fire_CarPark',
    name: 'Fire_CarPark',
    component: () => import('@/views/fire/Fire_CarPark.vue'),
    url: "/views/fire/Fire_CarPark.vue"
  }, {
    path: '/Fire_CarParkInfrastructure',
    name: 'Fire_CarParkInfrastructure',
    component: () => import('@/views/fire/Fire_CarParkInfrastructure.vue'),
    url: "/views/fire/Fire_CarParkInfrastructure.vue"
  }, {
    path: '/Fire_ControlAccessDoor',
    name: 'Fire_ControlAccessDoor',
    component: () => import('@/views/fire/Fire_ControlAccessDoor.vue'),
    url: "/views/fire/Fire_ControlAccessDoor.vue"
  }, {
    path: '/Pur_RequisitionForm',
    name: 'Pur_RequisitionForm',
    component: () => import('@/views/system/purchase/Pur_RequisitionForm.vue'),
    url: '/views/system/purchase/Pur_RequisitionForm.vue'
  }, {
    path: '/RequisitionFormList',
    name: 'RequisitionFormList',
    component: () => import('@/views/system/purchase/RequisitionFormList.vue'),
    url: '/views/system/purchase/RequisitionFormList.vue'
  }, {
    path: '/Finan_CostStatistics',
    name: 'Finan_CostStatistics',
    component: () => import('@/views/system/finance/Finan_CostStatistics.vue'),
    url: '/views/system/finance/Finan_CostStatistics.vue'
  }, {
    path: '/Finan_CostStatisticsWY',
    name: 'Finan_CostStatisticsWY',
    component: () => import('@/views/system/finance/Finan_CostStatisticsWY.vue'),
    url: '/views/system/finance/Finan_CostStatisticsWY.vue'
  }, {
    path: '/Finan_CostStatisticsHB',
    name: 'Finan_CostStatisticsHB',
    component: () => import('@/views/system/finance/Finan_CostStatisticsHB.vue'),
    url: '/views/system/finance/Finan_CostStatisticsHB.vue'
  }, {
    path: '/MeetingUsageCalendar',
    name: 'MeetingUsageCalendar',
    component: () => import('@/views/oa/oa/MeetingUsageCalendar.vue'),
    url: '/views/oa/oa/MeetingUsageCalendar.vue'
  },{
    path: '/Fire_CarAccessRecord',
    name: 'Fire_CarAccessRecord',
    component: () => import('@/views/fire/Fire_CarAccessRecord.vue'),
    url: '/views/fire/Fire_CarAccessRecord.vue'
  },{
    path: '/CarAccessRecordList',
    name: 'CarAccessRecordList',
    component: () => import('@/views/fire/CarAccessRecordList.vue'),
    url: '/views/fire/CarAccessRecordList.vue'
  }, {
    path: '/Sys_RoleDepartment',
    name: 'Sys_RoleDepartment',
    component: () => import('@/views/oa/oa/Sys_RoleDepartment.vue'),
    url: '/views/oa/oa/Sys_RoleDepartment.vue'
  }, {
    path: '/YD_checkin_month_apply',
    name: 'YD_checkin_month_apply',
    component: () => import('@/views/oa/MonthlyReport/YD_checkin_month_apply.vue'),
    url: '/views/oa/MonthlyReport/YD_checkin_month_apply.vue'
  }, {
    path: '/YD_date_holiday_type',
    name: 'YD_date_holiday_type',
    component: () => import('@/views/oa/MonthlyReport/YD_date_holiday_type.vue'),
    url: '/views/oa/MonthlyReport/YD_date_holiday_type.vue'
  }, {
    path: '/YD_HolidaysManager',
    name: 'YD_HolidaysManager',
    component: () => import('@/views/oa/MonthlyReport/YD_HolidaysManager.vue'),
    url: '/views/oa/MonthlyReport/YD_HolidaysManager.vue'
  }, {
      path: '/YD_YSB',
      name: 'YD_YSB',
      component: () => import('@/views/oa/MonthlyReport/YD_YSB.vue'),
      url: '/views/oa/MonthlyReport/YD_YSB.vue'
  }, {
      path: '/YD_SSSDKK',
      name: 'YD_SSSDKK',
      component: () => import('@/views/oa/MonthlyReport/YD_SSSDKK.vue'),
      url: '/views/oa/MonthlyReport/YD_SSSDKK.vue'
  }, {
      path: '/YD_performance_record',
      name: 'YD_performance_record',
      component: () => import('@/views/oa/MonthlyReport/YD_performance_record.vue'),
      url: '/views/oa/MonthlyReport/YD_performance_record.vue'
  }, {
      path: '/YD_corp_checkin_option',
      name: 'YD_corp_checkin_option',
      component: () => import('@/views/oa/MonthlyReport/YD_corp_checkin_option.vue'),
      url: '/views/oa/MonthlyReport/YD_corp_checkin_option.vue'
  }, {
      path: '/YD_overtime_work_approval_Z',
      name: 'YD_overtime_work_approval_Z',
      component: () => import('@/views/oa/MonthlyReport/YD_overtime_work_approval_Z.vue'),
      url: '/views/oa/MonthlyReport/YD_overtime_work_approval_Z.vue'
  }, {
    path: '/YD_reward_punishment_apply',
    name: 'YD_reward_punishment_apply',
    component: () => import('@/views/oa/MonthlyReport/YD_reward_punishment_apply.vue'),
    url: '/views/oa/MonthlyReport/YD_reward_punishment_apply.vue'
  }, {
    path: '/Fire_VideoIntercomDevice',
    name: 'Fire_VideoIntercomDevice',
    component: () => import('@/views/fire/Fire_VideoIntercomDevice.vue'),
    url: '/views/fire/Fire_VideoIntercomDevice.vue'
  }, {
    path: '/Fire_VideoIntercomDeviceEventLog',
    name: 'Fire_VideoIntercomDeviceEventLog',
    component: () => import('@/views/fire/Fire_VideoIntercomDeviceEventLog.vue'),
    url: '/views/fire/Fire_VideoIntercomDeviceEventLog.vue'
  }, {
    path: '/Sys_AppNoticeWord',
    name: 'Sys_AppNoticeWord',
    component: () => import('@/views/system/Sys_AppNoticeWord.vue'),
    url: '/views/system/Sys_AppNoticeWord.vue'
  }, {
    path: '/Finan_CommunityIncome',
    name: 'Finan_CommunityIncome',
    component: () => import('@/views/system/finance/Finan_CommunityIncome.vue'),
    url: '/views/system/finance/Finan_CommunityIncome.vue'
  }, {
      path: '/YD_JXExamJobDuty',
      name: 'YD_JXExamJobDuty',
      component: () => import('@/views/oa/oa/YD_JXExamJobDuty.vue'),
      url: '/views/oa/oa/YD_JXExamJobDuty.vue'
  }, {
      path: '/YD_JXExam',
      name: 'YD_JXExam',
      component: () => import('@/views/oa/oa/YD_JXExam.vue'),
      url: '/views/oa/oa/YD_JXExam.vue'
  }, {
      path: '/OA_PostLevel',
      name: 'OA_PostLevel',
      component: () => import('@/views/oa/oa/OA_PostLevel.vue'),
    url: '/views/oa/oa/OA_PostLevel.vue'
}    ,{
        path: '/YD_WS_WorkingSchedule',
        name: 'YD_WS_WorkingSchedule',
        component: () => import('@/views/oa/MonthlyReport/YD_WS_WorkingSchedule.vue'),
        url: '/views/oa/MonthlyReport/YD_WS_WorkingSchedule.vue'
    }    ,{
        path: '/Sys_SewageStation',
        name: 'Sys_SewageStation',
        component: () => import('@/views/system/sewage/Sys_SewageStation.vue'),
        url: '/views/system/sewage/Sys_SewageStation.vue'
    }    ,{
        path: '/Finance_SewageAgentia',
        name: 'Finance_SewageAgentia',
        component: () => import('@/views/system/sewage/Finance_SewageAgentia.vue'),
        url: '/views/system/sewage/Finance_SewageAgentia.vue'
  }, {
        path: '/Pur_MaterialsConfigType',
        name: 'Pur_MaterialsConfigType',
        component: () => import('@/views/system/purchase/Pur_MaterialsConfigType.vue'),
        url: '/views/system/purchase/Pur_MaterialsConfigType.vue'
  }, {
    path: '/Asset_DeptBaseConfig',
    name: 'Asset_DeptBaseConfig',
    component: () => import('@/views/system/asset/Asset_DeptBaseConfig.vue'),
    url: '/views/system/asset/Asset_DeptBaseConfig.vue'
  }, {
    path: '/Asset_DeptType',
    name: 'Asset_DeptType',
    component: () => import('@/views/system/asset/Asset_DeptType.vue'),
    url: '/views/system/asset/Asset_DeptType.vue'
  }, {
    path: '/Asset_DeptBaseConfigHasAssetDetail',
    name: 'Asset_DeptBaseConfigHasAssetDetail',
    component: () => import('@/views/system/asset/Asset_DeptBaseConfigHasAssetDetail.vue'),
    url: '/views/system/asset/Asset_DeptBaseConfigHasAssetDetail.vue'
  }, {
    path: '/Asset_DeptBaseConfigHasDeptAssetDetail',
    name: 'Asset_DeptBaseConfigHasDeptAssetDetail',
    component: () => import('@/views/system/asset/Asset_DeptBaseConfigHasDeptAssetDetail.vue'),
    url: '/views/system/asset/Asset_DeptBaseConfigHasDeptAssetDetail.vue'
  }, {
    path: '/OA_DayWorkReport',
    name: 'OA_DayWorkReport',
    component: () => import('@/views/oa/workplan/OA_DayWorkReport.vue'),
    url: '/views/oa/workplan/OA_DayWorkReport.vue'
  }, {
    path: '/OA_ResumeScreening',
    name: 'OA_ResumeScreening',
    component: () => import('@/views/oa/recruit/OA_ResumeScreening.vue'),
    url: '/views/oa/recruit/OA_ResumeScreening.vue'
  }, {
    path: '/OA_ResumeResult',
    name: 'OA_ResumeResult',
    component: () => import('@/views/oa/recruit/OA_ResumeResult.vue'),
    url: '/views/oa/recruit/OA_ResumeResult.vue'
  }, {
    path: '/Sys_TenderingProject',
    name: 'Sys_TenderingProject',
    component: () => import('@/views/system/Sys_TenderingProject.vue'),
    url: '/views/system/Sys_TenderingProject.vue'
  }, {
    path: '/Sys_TenderingAreaAndKeysDirector',
    name: 'Sys_TenderingAreaAndKeysDirector',
    component: () => import('@/views/system/Sys_TenderingAreaAndKeysDirector.vue'),
    url: '/views/system/Sys_TenderingAreaAndKeysDirector.vue'
  }    ,{
        path: '/Sys_ProjectBase',
        name: 'Sys_ProjectBase',
        component: () => import('@/views/system/Sys_ProjectBase.vue'),
        url: '/views/system/Sys_ProjectBase.vue'
  }, {
    path: '/OA_RecruitmentTask',
    name: 'OA_RecruitmentTask',
    component: () => import('@/views/oa/oa/recruit/OA_RecruitmentTask.vue'),
    url: '/views/oa/oa/recruit/OA_RecruitmentTask.vue'
  }, {
    path: '/OA_DailyRecruitmentWork',
    name: 'OA_DailyRecruitmentWork',
    component: () => import('@/views/oa/oa/recruit/OA_DailyRecruitmentWork.vue'),
    url: '/views/oa/oa/recruit/OA_DailyRecruitmentWork.vue'
  }, {
    path: '/Pur_AssetCodeLibrary',
    name: 'Pur_AssetCodeLibrary',
    component: () => import('@/views/system/purchase/Pur_AssetCodeLibrary.vue'),
    url: '/views/system/purchase/Pur_AssetCodeLibrary.vue'
  }, {
    path: '/AssetCodeLibraryList',
    name: 'AssetCodeLibraryList',
    component: () => import('@/views/system/purchase/AssetCodeLibraryList.vue'),
    url: '/views/system/purchase/AssetCodeLibraryList.vue'
  }, {
    path: '/Sys_Hospital',
    name: 'Sys_Hospital',
    component: () => import('@/views/system/Sys_Hospital.vue'),
    url: '/views/system/Sys_Hospital.vue'
  }, {
    path: '/Sys_School',
    name: 'Sys_School',
    component: () => import('@/views/system/Sys_School.vue'),
    url: '/views/system/Sys_School.vue'
  }, {
    path: '/Finan_PropertyConsumptionStatistics',
    name: 'Finan_PropertyConsumptionStatistics',
    component: () => import('@/views/system/finance/Finan_PropertyConsumptionStatistics.vue'),
    url: '/views/system/finance/Finan_PropertyConsumptionStatistics.vue'
  }, {
    path: '/Sys_TenderingReserveData',
    name: 'Sys_TenderingReserveData',
    component: () => import('@/views/system/Sys_TenderingReserveData.vue'),
    url: '/views/system/Sys_TenderingReserveData.vue'
  }, {
    path: '/Finan_EnvironmentConsumptionStatistics',
    name: 'Finan_EnvironmentConsumptionStatistics',
    component: () => import('@/views/system/finance/Finan_EnvironmentConsumptionStatistics.vue'),
    url: '/views/system/finance/Finan_EnvironmentConsumptionStatistics.vue'
  }, {
    path: '/Sys_WxMonitor',
    name: 'Sys_WxMonitor',
    component: () => import('@/views/system/Sys_WxMonitor'),
    url: '/views/system/finance/Finan_EnvironmentConsumptionStatistics.vue'
  }, {
    path: '/Sys_WxMonitor_Message',
    name: 'Sys_WxMonitor_Message',
    component: () => import('@/views/system/Sys_WxMonitor_Message'),
    url: '/views/system/Sys_WxMonitor_Message.vue'
  }, {
    path: '/OA_RecruitmentProgress',
    name: 'OA_RecruitmentProgress',
    component: () => import('@/views/oa/oa/recruit/OA_RecruitmentProgress.vue'),
    url: '/views/oa/oa/recruit/OA_RecruitmentProgress.vue'
  }, {
    path: '/Sys_SewageStationList',
    name: 'Sys_SewageStationList',
    component: () => import('@/views/sewage/Sys_SewageStationList.vue'),
    url: '/views/sewage/Sys_SewageStationList.vue'
  }, {
    path: '/Sys_SewageStationReportRepair',
    name: 'Sys_SewageStationReportRepair',
    component: () => import('@/views/system/Sys_SewageStationReportRepair.vue'),
    url: '/views/system/Sys_SewageStationReportRepair.vue'
  }, {
    path: '/SewageRepairSummary',
    name: 'SewageRepairSummary',
    component: () => import('@/views/system/sewageReportRepairWorkOrder/SewageRepairSummary.vue'),
    url: '/views/system/sewageReportRepairWorkOrder/SewageRepairSummary.vue'
  }, {
    path: '/Sys_SewageStationReportRepairArea',
    name: 'Sys_SewageStationReportRepairArea',
    component: () => import('@/views/system/Sys_SewageStationReportRepairArea.vue'),
    url: '/views/system/Sys_SewageStationReportRepairArea.vue'
  }    ,{
        path: '/OA_MeetingHotKeyTable',
        name: 'OA_MeetingHotKeyTable',
        component: () => import('@/views/oa/meeting/OA_MeetingHotKeyTable.vue'),
        url: '/views/oa/meeting/OA_MeetingHotKeyTable.vue'
    
  }, {
    path: '/Sys_TenderingArea',
    name: 'Sys_TenderingArea',
    component: () => import('@/views/system/Sys_TenderingArea.vue'),
    url: '/views/system/Sys_TenderingArea.vue'
  },{
    path: '/Sys_InformationBaseType',
    name: 'Sys_InformationBaseType',
    component: () => import('@/views/system/Sys_InformationBaseType.vue'),
    url: '/views/system/Sys_InformationBaseType.vue'
  }, {
    path: '/Sys_SewageStationReportRepairSpotCheck',
    name: 'Sys_SewageStationReportRepairSpotCheck',
    component: () => import('@/views/system/Sys_SewageStationReportRepairSpotCheck.vue'),
    url: '/views/system/Sys_SewageStationReportRepairSpotCheck.vue'
  }    ,{
        path: '/Sys_ProjectEvalTemplateDetail',
        name: 'Sys_ProjectEvalTemplateDetail',
        component: () => import('@/views/system/Sys_ProjectEvalTemplateDetail.vue'),
        url: '/views/system/Sys_ProjectEvalTemplateDetail.vue'
    }    ,{
        path: '/Sys_ProjectEvalTemplate',
        name: 'Sys_ProjectEvalTemplate',
        component: () => import('@/views/system/Sys_ProjectEvalTemplate.vue'),
        url: '/views/system/Sys_ProjectEvalTemplate.vue'
    }    ,{
        path: '/OA_EmployeeTag',
        name: 'OA_EmployeeTag',
        component: () => import('@/views/oa/employeetag/OA_EmployeeTag.vue'),
        url: '/views/oa/employeetag/OA_EmployeeTag.vue'
    },  {
      path: '/ResumeReview',
      name: 'ResumeReview',
      component: () => import('@/views/oa/oa/recruit/ResumeReview.vue'),
      url: '/views/oa/oa/recruit/ResumeReview.vue'
    },  {
      path: '/RecruitmentProgressSummary',
      name: 'RecruitmentProgressSummary',
      component: () => import('@/views/oa/oa/recruit/RecruitmentProgressSummary.vue'),
      url: '/views/oa/oa/recruit/RecruitmentProgressSummary.vue'
    }    ,{
        path: '/OA_MeetingTemplate',
        name: 'OA_MeetingTemplate',
        component: () => import('@/views/oa/meeting/OA_MeetingTemplate.vue'),
        url: '/views/oa/meeting/OA_MeetingTemplate.vue'
  }, { 
    path: '/Sys_TenderingProjectPrevYear',
    name: 'Sys_TenderingProjectPrevYear',
    component: () => import('@/views/system/Sys_TenderingProjectPrevYear.vue'),
    url: '/views/system/Sys_TenderingProjectPrevYear.vue'
  }, {
    path: '/Sewage_StationElectricityVoltageDevice',
    name: 'Sewage_StationElectricityVoltageDevice',
    component: () => import('@/views/sewage/Sewage_StationElectricityVoltageDevice.vue'),
    url: '/views/sewage/Sewage_StationElectricityVoltageDevice.vue'
  }, {
    path: '/Sewage_StationSensorDevice',
    name: 'Sewage_StationSensorDevice',
    component: () => import('@/views/sewage/Sewage_StationSensorDevice.vue'),
    url: '/views/sewage/Sewage_StationSensorDevice.vue'
  }, {
    path: '/Sewage_StationPHDevice',
    name: 'Sewage_StationPHDevice',
    component: () => import('@/views/sewage/Sewage_StationPHDevice.vue'),
    url: '/views/sewage/Sewage_StationPHDevice.vue'
  }, {
    path: '/Sewage_StationZhuoDuYiDevice',
    name: 'Sewage_StationZhuoDuYiDevice',
    component: () => import('@/views/sewage/Sewage_StationZhuoDuYiDevice.vue'),
    url: '/views/sewage/Sewage_StationZhuoDuYiDevice.vue'
  }, {
    path: '/ResumeWarehouse',
    name: 'ResumeWarehouse',
    component: () => import('@/views/oa/oa/recruit/ResumeWarehouse.vue'),
    url: '/views/oa/oa/recruit/ResumeWarehouse.vue'
  }, {
    path: '/Sewage_OpcServerGateWay',
    name: 'Sewage_OpcServerGateWay',
    component: () => import('@/views/sewage/Sewage_OpcServerGateWay.vue'),
    url: '/views/sewage/Sewage_OpcServerGateWay.vue'
  }, {
    path: '/SewageDailyConsumption',
    name: 'SewageDailyConsumption',
    component: () => import('@/views/sewage/SewageDailyConsumption.vue'),
    url: '/views/sewage/SewageDailyConsumption.vue'
  }    ,{
        path: '/DrugUseLog_Industry',
        name: 'DrugUseLog_Industry',
        component: () => import('@/views/system/sewage/DrugUseLog_Industry.vue'),
        url: '/views/system/sewage/DrugUseLog_Industry.vue'
    }    ,{
        path: '/DrugUseLog_Canteen',
        name: 'DrugUseLog_Canteen',
        component: () => import('@/views/system/sewage/DrugUseLog_Canteen.vue'),
        url: '/views/system/sewage/DrugUseLog_Canteen.vue'
    }]

export default viewgird
