<template>
    <view>
        <vol-box v-model="showTopic" :lazy="true" title="试卷答题" :height="640" :width="800" :padding="15">
            <view v-for="(row, index) in answerLists" :key="index" style="margin-bottom: 20px;">
                <view>{{ index + 1 }}、{{ row.TopicName }}（{{ row.Score }}分）</view>
                <!-- <view v-if="topic.isLecturer && row.TopicType == 3" style="margin-left: 20px;">
                    得分：
                    <view v-if="!topic.showScoreButton">{{ row.topicReply.Score }}</view>
                    <view v-else>
                        <el-input-number type="number" style="width: 150px;" placeholder="输入得分"
                        
                        @input="(val) => { inputNumberChange(val, index, row); }"
                        v-model="row.topicReply.Score">
                        </el-input-number>
                    </view>
                </view>
                <view v-else-if="topic.isLecturer && (row.TopicType == 1 || row.TopicType == 2)" style="margin-left: 20px;">
                    得分：{{ row.topicReply.Score }}
                </view>
                <view v-if="!topic.showSaveButton && (row.TopicType == 1 || row.TopicType == 2)" style="margin-left: 20px;">
                    正确答案：
                    <view v-for="(answerRow, answerIndex) in row.TopicAnswer" :key="answerIndex" style="color: red;">
                        <view v-show="answerRow.IsCorrectAnswer ==1">{{ answerRow.SerialNumber }}、</view>
                    </view>
                </view> -->
                <br>
                <view v-if="row.TopicType == 1 || row.TopicType == 4">
                    <!-- 单选题、判断题 -->
                    <el-radio-group v-model="row.Answers" type="vertical" :disabled="row.IsAnswer">
                        <div v-for="(answerRow, answerIndex) in row.TopicAnswer" :key="answerIndex" style="width:100%;">
                            <el-radio :label="answerRow.AnswerID">{{ answerRow.SerialNumber + '、' + answerRow.AnswerName
                                }}</el-radio>
                        </div>
                    </el-radio-group>
                </view>
                <view v-else-if="row.TopicType == 2">
                    <!-- 多选题 -->
                    <el-checkbox-group v-model="row.Answers" type="vertical" :disabled="row.IsAnswer">
                        <div v-for="(answerRow, answerIndex) in row.TopicAnswer" :key="answerIndex" style="width:100%;">
                            <el-checkbox :label="answerRow.AnswerID">{{ answerRow.SerialNumber + '、' +
            answerRow.AnswerName
                                }}</el-checkbox>
                        </div>
                    </el-checkbox-group>
                </view>
                <view v-else-if="row.TopicType == 3">
                    <!-- 文字题 -->
                    <textarea style="width: 95%;padding: 8rpx;height: 100px;border:1px solid #e3dcdc;margin: 10px 0;"
                        placeholder="请填写" v-model="row.Answers" :disabled="row.IsAnswer"></textarea>
                </view>

                <br>
                <view style="margin-bottom: 20px;"></view>
            </view>
            <!-- <view v-if="topic.isLecturer">员工：{{ topic.stuffData.UserName }} 总得分：{{ topic.evalScore }}<br></view>
            <template #footer>
                <view v-show="topic.showSaveButton">
                    <el-button size="mini" type="primary" @click="saveTopic()">提交答卷</el-button>
                </view>
                <view v-show="topic.showScoreButton">
                    <el-button size="mini" type="primary" @click="saveTopicScore()">提交分数</el-button>
                </view>
            </template> -->

        </vol-box>
    </view>
</template>

<script>
import VolBox from "@/components/basic/VolBox.vue";
export default {
    components: {
        "vol-box": VolBox,
    },
    data() {
        return {
            showTopic: false,
            parentRow: {},
            topicAnswerList: [],
            topicLists: [],
            answerLists: []
        };
    },
    methods: {
        async getVideoTopicAnswer() {
            var url = "api/Tra_CourseVideoAnswerResult/getPageData"
            await this.http.post(url, {
                wheres: JSON.stringify(
                    [{
                        name: 'TraType',
                        value: 1
                    }, {
                        name: 'TraKeyID',
                        value: this.parentRow.detailID
                    }]
                ),
            }, false).then(result => {
                if (result != null && result.rows != null) {
                    this.topicAnswerList = result.rows
                }
            })
        },
        async getVideo() {
            var url = "api/Tra_Course/getDetailPage"
            await this.http.post(url, {
                value: this.parentRow.CourseID,
                wheres: JSON.stringify(
                    [{
                        name: 'VideoID',
                        value: this.parentRow.VideoID
                    }]
                ),
            }, false).then(result => {
                if (result != null && result.rows != null) {
                    var row = result.rows[0]
                    if (row.Topics != null) {
                        row.Topics.forEach(t => {
                            this.topicLists.push(t)
                        })
                    }
                }
            })
        },
        openTopic(row) {
            this.showTopic = true;
            this.parentRow = row
            this.topicLists = []
            this.topicAnswerList = []
            var _this = this
            this.$nextTick(async () => {
                await this.getVideo();
                await this.getVideoTopicAnswer();

                this.answerLists = []
                this.topicLists.forEach(t => {
                    var w = this.topicAnswerList.filter(s => s.TopicID == t.TopicID)
                    if (w != null && w.length > 0) {
                        t.Answers = w[0].Answers
                        t.IsAnswer = false
                        if (!_this.base.checkEmpty(w[0].Answers)) {
                            t.IsAnswer = true
                            if (t.TopicType == 2) {
                                t.Answers = w[0].Answers.split(',').map(Number);
                            }
                            else if (t.TopicType == 1 || t.TopicType == 4) {
                                t.Answers = parseInt(w[0].Answers)
                            }
                        }

                        this.answerLists.push(t)
                    }
                })
            })
        },
        saveTopic() {
            this.$confirm('确定提交答卷吗？提交后将不可再修改，请确认题目是否都已填写', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false
            }).then(() => {
                var opt = "add";
                if (this.topic.topicRowsData != null && this.topic.topicRowsData.length > 0) {
                    opt = "update";
                }
                var detailData = [];
                this.topic.rowsData.forEach(x => {
                    var Answers = x.Answers;
                    if (x.TopicType == 2) {
                        Answers = (Answers != null && Answers != "") ? Answers.join(",") : ""
                    }
                    var Score = 0;
                    if (x.TopicType <= 2 && x.Answers != null) {
                        //自动计算选择题分数
                        var okAnswer = x.TopicAnswer.filter(s => s.IsCorrectAnswer == 1);
                        if (okAnswer != null && okAnswer.length > 0) {
                            //判断填写的答案是否在正确答案内
                            if (x.TopicType == 1 && x.Answers == okAnswer[0].AnswerID) {
                                Score += x.Score;
                            }
                            else if (x.TopicType == 2) {
                                //多选题要全部填写正确才有分
                                var okNum = 0;
                                x.Answers.forEach(x => {
                                    var f = okAnswer.filter(f => f.AnswerID == x);
                                    if (f != null && f.length > 0) {
                                        okNum += 1;
                                    }
                                })
                                if (okNum == okAnswer.length) {
                                    Score += x.Score;
                                }
                            }
                        }
                    }

                    var StuffTopicID = 0;
                    var w = this.topic.topicRowsData.filter(s => s.ImplementID == this.topic.implementID && s.StuffID == this.topic.stuffID && s.TopicID == x.TopicID)
                    if (w != null && w != "") {
                        StuffTopicID = w[0].StuffTopicID
                    }
                    if (StuffTopicID == 0) {
                        detailData.push({
                            ImplementID: this.topic.implementID,
                            StuffID: this.topic.stuffID,
                            TopicID: x.TopicID,
                            Answers: Answers,
                            Score: Score
                        })
                    }
                    else {
                        detailData.push({
                            StuffTopicID: StuffTopicID,
                            ImplementID: this.topic.implementID,
                            StuffID: this.topic.stuffID,
                            TopicID: x.TopicID,
                            Answers: Answers,
                            Score: Score
                        })
                    }
                })
                let params = {
                    mainData: this.topic.stuffData,
                    detailData: detailData,
                }
                //console.log(JSON.stringify(params))
                // return;
                this.http.post("api/Tra_ImplementationStuff/update", params, true).then(result => {
                    if (!result.status) {
                        this.$Message.error(result.message);
                        return;
                    }
                    this.$Message.success(result.message)
                    //保存成功后刷新页面数据
                    this.showTopic = false;
                })
            }).catch(() => {

            })

        },
        saveTopicScore() {
            this.$confirm('确定提交分数吗？提交后将不可再修改', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false
            }).then(() => {
                var isBreak = false;
                var index = 0;
                var msg = '';
                this.topic.rowsData.forEach(x => {
                    index += 1;
                    if (x.topicReply != null && x.topicReply.Score > 0 && x.topicReply.Score > x.Score) {
                        msg = "题目 " + index + "、" + x.TopicName + " 填写分数大于设定的总分，请修改";
                        isBreak = true;
                        x.topicReply.Score = '';
                        return false;
                    }
                })
                if (isBreak) {
                    this.$Message.error(msg);
                    return;
                }
                var opt = "add";
                if (this.topic.topicRowsData != null && this.topic.topicRowsData.length > 0) {
                    opt = "update";
                }
                var detailData = [];
                this.topic.evalScore = 0;
                this.topic.stuffData.EvalScore = 0;
                this.topic.rowsData.forEach(x => {
                    var Answers = x.Answers;
                    if (x.TopicType == 2) {
                        Answers = (Answers != null && Answers != "") ? Answers.join(",") : ""
                    }
                    if (x.topicReply.Score == '' || x.topicReply.Score == null) {
                        x.topicReply.Score = 0;
                    }
                    x.topicReply.Score = parseInt(x.topicReply.Score)
                    this.topic.evalScore += parseInt(x.topicReply.Score);
                    var StuffTopicID = 0;
                    var w = this.topic.topicRowsData.filter(s => s.ImplementID == this.topic.implementID && s.StuffID == this.topic.stuffID && s.TopicID == x.TopicID)
                    if (w != null && w != "") {
                        StuffTopicID = w[0].StuffTopicID
                    }
                    if (StuffTopicID == 0) {
                        detailData.push({
                            ImplementID: this.topic.implementID,
                            StuffID: this.topic.stuffID,
                            TopicID: x.TopicID,
                            Answers: Answers,
                            Score: parseInt(x.topicReply.Score)
                        })
                    }
                    else {
                        detailData.push({
                            StuffTopicID: StuffTopicID,
                            ImplementID: this.topic.implementID,
                            StuffID: this.topic.stuffID,
                            TopicID: x.TopicID,
                            Answers: Answers,
                            Score: parseInt(x.topicReply.Score)
                        })
                    }
                })
                this.topic.stuffData.EvalScore = this.topic.evalScore;
                this.topic.stuffData.EvalDate = this.base.getDate()
                let params = {
                    mainData: this.topic.stuffData,
                    detailData: detailData,
                }
                //console.log(JSON.stringify(params))
                // return;
                this.http.post("api/Tra_ImplementationStuff/update", params, true).then(result => {
                    if (!result.status) {
                        this.$Message.error(result.message);
                        return;
                    }
                    this.$Message.success(result.message)
                    //保存成功后刷新页面数据
                    this.$emit("parentCall", $vue => {
                        $vue.resetDetailTable();
                    })
                    this.showTopic = false;
                })
            }).catch(() => {

            })

        },
        inputNumberChange(value, index, row) {
            if (row.Score < value) {
                this.$Message.error("题目 " + index + "、" + row.TopicName + " 填写分数大于设定的总分，请修改");
                //row.topicReply.Score = 0;
                //this.topic.rowsData[index-1].topicReply.Score = 0;
                return;
            }
            this.topic.evalScore = 0;
            this.topic.rowsData.forEach(x => {
                if (x.topicReply != null && x.topicReply.Score > 0) {
                    this.topic.evalScore += parseInt(x.topicReply.Score);
                }
            })
        }
    },
};

</script>

<style lang="less" scoped>
input[type=number] {
    -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
</style>